import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptorModule } from './core/interceptors/request-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SignOutComponent } from './authentication/sign-out/sign-out.component';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { AppInsightsService } from './core/services/appinsight.service';
import {  MsalBroadcastService, MsalGuard, MsalModule, MsalService, MSAL_INSTANCE} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.issuer,
      redirectUri: environment.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    SignOutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpRequestInterceptorModule,
    BrowserAnimationsModule,
    MsalModule,
    NgHttpLoaderModule.forRoot()
  ],
  providers: [AppInsightsService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      }],
  bootstrap: [AppComponent],
 
})
export class AppModule { }
